import React from "react"
import Container from "../components/container"
import { graphql } from "gatsby"
export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Container whyDevNano={data.whyDevNano} author={data.author}>
      <h1>{post.frontmatter.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </Container>
  )
}
// FIXME: using a $langKey in a dummy query as a workaround for
// gatsby failing with   15:25  error  Variable "$langKey" is never used  graphql/template-strings
// otherwise
// see https://github.com/gatsbyjs/gatsby/issues/11205#issuecomment-456435864
export const query = graphql`
  query($slug: String!, $langKey: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
    ...HeaderFragment
    dummy: markdownRemark(fields: { slug: { eq: $langKey } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
